@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");

.navbar-dimmed {
  z-index: 1000;
}
.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1040;
}
.custom-modal {
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
}

.custom-modal .modal-body {
  padding: 20px;
  font-family: "Work Sans", sans-serif;
  color: #333;
}

.custom-modal .modal-body h1 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #2d2b3a;
  margin-bottom: 15px;
}

.custom-modal .modal-body h6 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
}

.custom-modal .modal-body p {
  font-size: 18px !important;
  line-height: 1.5;
  margin-bottom: 15px;
  color: #555;
}

.custom-modal .modal-body ul {
  padding-left: 20px;
}

.custom-modal .modal-body ul li {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 10px;
}

.custom-modal .modal-body ul li strong {
  color: #2d2b3a;
  font-weight: 600;
}
.img2 {
  height: 300px !important;
  width: 300px !important;
}
.custom-button {
  background-color: #6c55f9;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100px;
}

.custom-button:hover {
  background-color: #5a47d3;
}

@media (min-width: 992px) {
  .custom-modal {
    width: 80%;
  }
}

/* For tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .custom-modal {
    width: 90%;
  }
}

/*tablets and landscape smartphones */
@media (min-width: 576px) and (max-width: 767px) {
  .custom-modal {
    width: 95%;
    margin: 0 auto;
  }
}

/*smartphones */
@media (max-width: 575px) {
  .custom-modal {
    width: 100%;
  }
}
